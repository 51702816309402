<script lang="ts">
  import { fly, type FlyParams } from "svelte/transition";
  import { twMerge } from "tailwind-merge";
  import { clickOutside } from "./click_outside";
  import Button from "../Buttons/Button.svelte";
  import { keypressEvent } from "../actions/KeypressEvents";

  export let id;
  export let showDropdown = { [id]: false };
  export let fixed: boolean = false;

  export let maxContentWidth = ""; // use full class i.e. max-w-23
  export let maxContextHeight = ""; // use full class

  export let position: string = "right-0"; // full tw classes
  export let flyin: FlyParams = { y: -10, delay: 0, duration: 200 };
  export let flyout: FlyParams = { y: -10, delay: 0, duration: 200 };

  export let transparentBackground: boolean = false;
  export let noPadding = false;
  export let noBorder = false;
  export let noShadow = false;

  export let fixedXOffset: number = 0;
  export let disabled: boolean = false;
  export let canScroll: boolean = false;
  export let noDefaultContentEnterEvent: boolean = false;
  export let singleElementUnfocus: boolean = false;

  let fixedX: number = 0;
  let fixedY: number = 0;

  function clickOutsideDropdown(col_id) {
    if (showDropdown[col_id]) showDropdown[col_id] = false;
  }

  function toggleDropdown(event, col_id) {
    if (disabled) {
      return;
    }

    if (fixed) {
      fixedX = event.pageX + fixedXOffset;
      fixedY = event.pageY + 15;
    }
    showDropdown[col_id] = !showDropdown[col_id];
  }

  const onEnterEvent = (e) => {
    if (!noDefaultContentEnterEvent) {
      toggleDropdown(e, id);
    }
  };

  const onUnfocusEvent = (e) => {
    if (singleElementUnfocus) {
      toggleDropdown(e, id);
    }
  };

  $: dropdownContentClasses = twMerge(
    "z-50",
    !noPadding && "p-2",
    !noBorder && "border rounded-md border-light-800",
    !noShadow && "shadow-lg",
    transparentBackground ? "bg-transparent border-none shadow-none" : "bg-white",
    canScroll && "overflow-y-auto h-full pb-0",
    position,
    fixed ? "fixed" : "absolute",
    maxContentWidth,
    maxContextHeight
  );
</script>

<div {id} class="relative" use:clickOutside on:outclick={() => clickOutsideDropdown(id)}>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    use:keypressEvent
    on:enter|stopPropagation={(e) => toggleDropdown(e, id)}
    on:click|stopPropagation={(e) => toggleDropdown(e, id)}
    role="button"
    tabindex="0"
  >
    <slot name="dropdown-trigger" tabindex="0" role="button">
      <Button buttonText="Dropdown trigger" />
    </slot>
  </div>

  {#if showDropdown[id]}
    {#if fixed}
      <div
        style="top: {fixedY}px; left: {fixedX}px; width: fit-content;"
        class={dropdownContentClasses}
        out:fly={flyout}
        in:fly={flyin}
        use:keypressEvent
        on:enter={onEnterEvent}
        role="button"
        tabindex="0"
        on:focusout={onUnfocusEvent}
        on:keypress
      >
        <slot name="dropdown-content" />
      </div>
    {:else}
      <div
        class={dropdownContentClasses}
        out:fly={flyout}
        in:fly={flyin}
        use:keypressEvent
        on:enter={onEnterEvent}
        role="button"
        tabindex="0"
        on:focusout={onUnfocusEvent}
        on:keypress
      >
        <slot name="dropdown-content" />
      </div>
    {/if}
  {/if}
</div>
