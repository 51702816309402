import * as amplitude from "@amplitude/analytics-browser";

type TrackingDefaultsType = {
  attribution?: boolean;
  pageViews?: boolean;
  sessions?: boolean;
  formInteractions?: boolean;
  fileDownloads?: boolean;
};

type ClientTrackingParams = {
  apiKey: string;
  defaults?: TrackingDefaultsType;
  env: string;
};

const TrackingDefaultsDefaults: TrackingDefaultsType = {
  attribution: false,
  pageViews: true,
  sessions: true,
  formInteractions: false,
  fileDownloads: true,
};

// "5d8fc3e8ab7f2fa00146f8d83ac92bb" // PillarGRC Project
export default class AmplitudeClientTracking {
  apiKey: string;
  defaults: TrackingDefaultsType;
  instance: any;
  env: string;

  constructor(params: ClientTrackingParams) {
    this.apiKey = params.apiKey;
    this.defaults = { ...(params.defaults || {}) };
    this.instance = null;
    this.env = params.env;
  }

  public init(): any {
    amplitude.init(this.apiKey, {
      defaultTracking: { ...TrackingDefaultsDefaults, ...this.defaults },
    });

    this.instance = amplitude;
    return this;
  }

  public trackCustomEvent(customEvent, optionalProperties = {}): void {
    if (this.env === "local") {
      return;
    }

    this.instance?.track(customEvent, optionalProperties);
  }

  public identifyBulkEvent(key, params): void {
    const identifyEvent = new this.instance.Identify();
    identifyEvent.set(key, params);
    this.instance?.identify(identifyEvent);
  }
}

// export const trackPageView = (page) => amplitude.track(page);
