<script lang="ts">
  import Userback from "@userback/widget";
  import { page } from "@inertiajs/svelte";
  import { onMount } from "svelte";
  import { useUserInterface } from "../modules/global/models/User";
  import { CLIENT_ORGANISATION } from "../modules/global/constants";

  $: current_user = $page.props.current_user;
  $: organisation = $page.props.current_organisation;
  $: clientOrganisation = organisation.type === CLIENT_ORGANISATION;

  const auditToken = "41255|83536|al0qODOm1FkcArhxPQR3E1STS";
  const clientToken = "41255|83689|al0qODOm1FkcArhxPQR3E1STS";

  $: token = clientOrganisation ? clientToken : auditToken;

  onMount(() => {
    Userback(token, { native_screenshot: true }).then((ub) => {
      ub.email = current_user?.email;
      ub.identify(current_user?.id, {
        name: useUserInterface(current_user).fullname(),
        role: useUserInterface(current_user).role,
        email: current_user?.email,
        organisation: organisation?.name,
        organisation_type: organisation?.type,
        organisation_id: organisation?.id,
      });
    });
  });
</script>
