<script lang="ts">
  import { twMerge } from "tailwind-merge";

  export let size: "xs" | "sm" | "md" | "lg" = "sm";

  const xsSpinnerClass = "h-[0.75rem] w-[0.75rem]";
  const smSpinnerClass = "h-[0.75rem] w-[0.75rem]";
  const mdSpinnerClass = "h-[1rem] w-[1rem]";
  const lgSpinnerClass = "h-[1.375rem] w-[1.375rem]";
  const animateClass = "animate-spin";

  $: spinnerClass = twMerge(
    size === "xs" && xsSpinnerClass,
    size === "sm" && smSpinnerClass,
    size === "md" && mdSpinnerClass,
    size === "lg" && lgSpinnerClass,
    animateClass,
    $$props.class
  );
</script>

<svg class={spinnerClass} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
  <path
    class="opacity-75"
    fill="currentColor"
    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
  />
</svg>
