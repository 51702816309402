import axios from "axios";
import { createInertiaApp } from "@inertiajs/svelte";
import PersistentLayout from "../Layouts/PersistentLayout.svelte";
import LoadingLayout from "../Layouts/LoadingLayout.svelte";

// eslint-disable-next-line import/no-unresolved
import "../assets/stylesheets/application.css";

const csrfToken = document.querySelector("meta[name=csrf-token]").content;
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

export const withVite = (name) => {
  const pages = import.meta.glob("../Pages/**/**/**/**/*.svelte");
  // eslint-disable-next-line no-restricted-syntax
  for (const path in pages) {
    if (path.endsWith(`${name.replace(".", "/")}.svelte`)) {
      return typeof pages[path] === "function" ? pages[path]() : pages[path];
    }
  }

  throw new Error(`Page not found: ${name}`);
};

export const doesNotRequireLayout = (name) => {
  const arr = ["login", "onboarding", "Onboarding", "Deactivated", "magic_status",
    "Invitations/Welcome", "/min"];

  return arr.filter((e) => name.includes(e)).length > 0;
};

createInertiaApp({
  progress: {
    showSpinner: true,
    includeCSS: true,
    color: "#29d",
  },
  resolve: (name) => {
    const requiresLayout = !doesNotRequireLayout(name);
    return withVite(name).then((page) => ({ layout: requiresLayout ? PersistentLayout : LoadingLayout, ...page }));
  },
  setup({ el, App, props }) {
    new App({ target: el, props });
  },
});
