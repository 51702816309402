export default class Toast {
  id: string;
  message: string;
  type: string;
  persist: boolean;
  timeout: number;

  constructor(
    id: string,
    message: string,
    type: string,
    persist: boolean = false,
    timeout: number = 4000
  ) {
    this.id = id;
    this.message = message;
    this.type = type;
    this.persist = persist;
    this.timeout = timeout;
  }
}
