import { writable, get } from "svelte/store";
import type { Writable } from "svelte/store";
import type Toast from "./Components/Toasties/Toast";

// We store it in session rather than an array.
export const toastMessages: Writable<Toast | null> = writable();

export const amplitudeStore: Writable<any> = writable();
export const trackAmplitudeEvent = (event, params) => {
  get(amplitudeStore)?.trackCustomEvent(event, params);
};

type OrganisationCardLibraryStoreType = {
  loaded: boolean;
};

export const organisationCardLibrary: Writable<OrganisationCardLibraryStoreType> = writable({
  loaded: false,
});
