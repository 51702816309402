<script lang="ts">
  import ProfileTab from "../navigation/ProfileTab.svelte";

  export let header: string;
  export let current_user;
</script>

<div class="gradient relative">
  <div class="flex flex-row items-center justify-between py-2 pr-5">
    <span class="page-header">{header}</span>
    <div class="absolute right-5">
      <ProfileTab {current_user} />
    </div>
  </div>
</div>

<style lang="scss">
  .gradient {
    @apply bg-gradient-to-r from-gradient-left to-gradient-right h-17 flex;
  }

  .page-header {
    @apply flex px-10 text-xl text-gray-200 items-center whitespace-nowrap;
  }
</style>
