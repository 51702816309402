<script lang="ts">
  import { twMerge } from "tailwind-merge";

  export let tip = "";
  export let top = false;
  export let right = false;
  export let bottom = false;
  export let left = false;
  export let active = false;
  export let disabled = false;

  const tipClass = twMerge(
    "tooltip",
    top && "left-1/2 top-0 transform -translate-y-full -translate-x-1/2 -mt-2",
    bottom && "left-1/2 bottom-0 transform translate-y-full -translate-x-1/2 -mb-2",
    right && "right-0 transform translate-x-full -mr-2 -mt-6",
    left && "left-0 transform -translate-x-full -ml-2 -mt-0",
    $$props.class
  );

  const defaultTipClass = twMerge("default-tip", $$props.defaultTipClass);
</script>

{#if disabled}
  <div>
    <slot />
  </div>
{:else}
  <div class="tooltip-wrapper">
    <span class="tooltip-slot">
      <slot />
    </span>
    <div class={tipClass} class:active class:left class:right class:bottom class:top>
      {#if tip}
        <div class={defaultTipClass}>{tip}</div>
      {:else}
        <slot name="custom-tip" />
      {/if}
    </div>
  </div>
{/if}

<style lang="scss">
  .default-tip {
    font-size: 0.7rem;
    display: inline-block;
    padding: 2px 12px;
    border-radius: 6px;

    @apply bg-gray-600 bg-opacity-100 text-white normal-case font-normal;
  }

  .tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  .tooltip {
    z-index: 999;
    position: absolute;
    font-family: inherit;
    display: inline-block;
    white-space: nowrap;
    color: inherit;
    opacity: 0;
    visibility: hidden;
    transition: opacity 150ms, visibility 150ms;
  }
  .tooltip.top {
    ::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(75, 85, 99) transparent transparent transparent;
    }
  }
  .tooltip.bottom {
    ::after {
      content: " ";
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent rgba(75, 85, 99) transparent;
    }
  }
  .tooltip.left {
    ::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%; /* To the left of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent rgba(75, 85, 99);
    }
  }
  .tooltip.right {
    ::after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 100%; /* To the right of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent rgba(75, 85, 99) transparent transparent;
    }
  }
  .tooltip.active {
    opacity: 1;
    visibility: initial;
  }
  .tooltip-slot:hover + .tooltip {
    opacity: 1;
    visibility: initial;
  }
</style>
