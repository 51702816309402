<script lang="ts">
  import { Cog, Icon, Logout } from "svelte-hero-icons";
  import { router } from "@inertiajs/svelte";
  import UserAvatar from "../util/User/UserAvatar.svelte";
  import Dropdown from "../util/Dropdown/dropdown.svelte";
  import { useUserInterface } from "../../modules/global/models/User";
  import Button from "../util/Buttons/Button.svelte";

  export let current_user;

  let profileDropdown = { profile: false };

  const navAccount = () => {
    profileDropdown.profile = false;
    router.visit("/account");
  };

  const navLogout = () => {
    profileDropdown.profile = false;
    router.visit("/logout");
  };
</script>

<div class="profile_tab">
  <user_details>
    <span class="font-regular text-xs text-white">
      {useUserInterface(current_user).fullname()}
    </span>
  </user_details>
  <Dropdown id="profile" bind:showDropdown={profileDropdown} position="top-8 right-0">
    <div slot="dropdown-trigger" class="cursor-pointer -ml-4">
      <UserAvatar user={current_user} size="xs" noMargin />
    </div>
    <div slot="dropdown-content">
      <div class="flex flex-col gap-1">
        <Button type="ghost" noBorder size="md" notCentered on:click={navAccount}>
          <div slot="iconLeft">
            <Icon src={Cog} size="14" solid />
          </div>
          Settings
        </Button>
        <Button type="ghost" noBorder size="md" notCentered on:click={navLogout}>
          <div slot="iconLeft">
            <Icon src={Logout} size="14" solid />
          </div>
          Logout
        </Button>
      </div>
    </div>
  </Dropdown>
</div>

<style lang="postcss">
  .profile_tab {
    @apply flex flex-row h-auto gap-8 items-center whitespace-nowrap;
  }
  user_details {
    @apply flex flex-row w-full gap-3 items-center read-only:cursor-default;
  }

  .text-email {
    @apply font-medium font-light text-xs text-neutral-400 w-[9.5rem] overflow-hidden;
    text-overflow: ellipsis;
  }
</style>
