<script lang="ts">
  import { twMerge } from "tailwind-merge";
  import Tooltip from "../../util/Tooltip/Tooltip.svelte";

  export let state: "min" | "full" = "full";
  export let hidden = false;
  export let tip = "";

  const bubbleDisplayClasses = "flex items-center justify-center";
  const bubbleSizeClasses = "w-5 h-5 rounded-md";
  const bubbleTextClasses = "text-white";

  const bubbleActiveClasses = "bg-yellow-500";
  const bubbleInActiveClasses = "bg-gray-200";

  const bubbleMinStateClasses = "w-2.5 h-2.5 rounded-md";

  let navBubbleClasses: string;
  $: navBubbleClasses = twMerge(
    bubbleDisplayClasses,
    state === "min" ? bubbleMinStateClasses : bubbleSizeClasses,
    bubbleTextClasses,
    hidden && "hidden",
    $$props.active && bubbleActiveClasses,
    !$$props.active && bubbleInActiveClasses,
    $$props.class
  );
</script>

<Tooltip disabled={!$$props.active} {tip} right>
  <div class={navBubbleClasses}>
    <slot />
  </div>
</Tooltip>
