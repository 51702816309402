import { toastMessages } from "../../ApplicationStore";
import Toast from "./Toast";

type toastOptionsType = {
  persist: boolean;
  timeout: number;
};

const toastOptionsDefaults: toastOptionsType = {
  persist: false,
  timeout: 4000,
};

const generateID = () => Math.random()
  .toString(36)
  .replace(/[^a-z]+/g, "");

export function send(
  message,
  type = "default",
  options: toastOptionsType = toastOptionsDefaults,
) {
  const toast: Toast = new Toast(
    generateID(),
    message,
    type,
    options.persist,
    options.timeout,
  );
  toastMessages.set(toast);
}

export function danger(
  message: string,
  options: toastOptionsType = toastOptionsDefaults,
) {
  send(message, "error", options);
}

export function warning(
  message: string,
  options: toastOptionsType = toastOptionsDefaults,
) {
  send(message, "warning", options);
}

export function info(
  message: string,
  options: toastOptionsType = toastOptionsDefaults,
) {
  send(message, "info", options);
}

export function success(
  message: string,
  options: toastOptionsType = toastOptionsDefaults,
) {
  send(message, "success", options);
}
