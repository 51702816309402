<script>
  import { onMount } from "svelte";
  import { router } from "@inertiajs/svelte";
  import PillarLogo from "../assets/pillar-bars-diff-length-norm.png";
  import Spinner from "../Components/util/Spinner.svelte";

  let timer = 1;
  setInterval(() => {
    if (timer > 0) {
      timer -= 1;
    }
  }, 1000);
</script>

{#if timer > 0}
  <div
    class="top-0 right-0 left-0 bottom-0 z-50 absolute bg-white flex items-center justify-center pointer-events-none"
  >
    <div class="flex flex-col px-3 gap-1 items-center m-2">
      <img src={PillarLogo} alt="Pillar Icon" class="-mt-1 w-64 h-auto" />
    </div>
  </div>
{:else}
  <slot />
{/if}
