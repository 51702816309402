<script lang="ts">
  import { twMerge } from "tailwind-merge";
  import LoadingSpinner from "./LoadingSpinner.svelte";

  type ButtonType =
    | "primary"
    | "secondary"
    | "success"
    | "ghost"
    | "extra-danger"
    | "danger"
    | "dashed"
    | "warning"
    | "headless"; // custom styling - use wisely

  export let type: ButtonType = "primary";
  export let size: "xs" | "sm" | "md" = "sm";
  export let noBorder = false;
  export let notCentered = false;
  export let noShadow = false;

  export let loading = false;
  export let disabled = false;
  export let loadingText = "";

  // Defaults
  const defaultBtn = "rounded-md";
  const btnBordered = "border";
  const btnNoBorder = "border-transparent hover:border-opacity-0 active:border-opacity-0";
  const btnFocus = "focus:animate-pop";
  const btnShadow = "focus:shadow-md";

  const btnXSmall = "w-auto text-[0.6rem] py-1 px-2 whitespace-nowrap";
  const btnSmall = "min-w-18 w-auto py-3 px-4 h-4 text-xs whitespace-nowrap";
  const btnMedium = "min-w-28 w-auto py-4 px-4 h-6 text-xs whitespace-nowrap";

  // Variants
  const btnPrimary = "bg-blue-500 border-blue-500 text-white";
  const btnPrimaryHover = "hover:bg-blue-600 hover:border-blue-600";
  const btnPrimaryActive = "active:bg-blue-600 active:border-blue-600";
  const btnPrimaryDisabled = "hover:bg-blue-500 active:bg-blue-500";

  const btnSecondary = "bg-gray-700 border-gray-700 text-white";
  const btnSecondaryHover = "hover:bg-gray-800 hover:border-gray-800";
  const btnSecondaryActive = "active:bg-gray-800 active:border-gray-800";
  const btnSecondaryDisabled = "hover:bg-gray-700 active:bg-gray-700";

  const btnSuccess = "bg-green-500 border-green-500 text-white";
  const btnSuccessHover = "hover:bg-green-600 hover:border-green-600";
  const btnSuccessActive = "active:bg-green-600 active:border-green-600";
  const btnSuccessDisabled = "hover:bg-green-500 active:bg-green-500";

  // This variant can be both solid and just words
  const btnDangerSolid = "bg-red-500 border-red-500 text-white";
  const btnDangerSolidHover = "hover:bg-red-600 hover:border-red-600";
  const btnDangerSolidActive = "active:bg-red-600 active:border-red-600";
  const btnDangerSolidDisabled = "hover:bg-red-500 active:bg-red-500";
  const btnDangerText = "bg-transparent border-transparent text-red-500";
  const btnDangerTextHover = "hover:text-red-600";
  const btnDangerTextActive = "active:text-red-600 active:shadow-none focus:shadow-none";
  const btnDangerTextDisabled = "";

  const btnWarning = "bg-orange-400 border-orange-400 text-white";
  const btnWarningHover = "hover:bg-orange-500 hover:border-orange-500";
  const btnWarningActive = "active:bg-orange-500 active:border-orange-500";
  const btnWarningDisabled = "hover:bg-orange-400 active:bg-orange-400";

  const btnDashed = "bg-transparent text-neutral-400 border-neutral-400 border-dashed";
  const btnDashedHover = "hover:text-neutral-900 hover:border-neutral-900 hover:bg-transparent";
  const btnDashedActive = "active:bg-transparent active:text-neutral-900 active:border-opacity-60";
  const btnDashedDisabled = "hover:bg-transparent active:bg-transparent";
  const btnDashedFocus = "focus:shadow-none";

  const btnGhost = "bg-transparent text-neutral-500 border-neutral-400 border-opacity-30";
  const btnGhostHover = "hover:bg-neutral-300 hover:bg-opacity-20 hover:border-opacity-60";
  const btnGhostActive = "active:bg-neutral-500 active:bg-opacity-20 active:border-opacity-60";
  const btnGhostDisabled = "hover:bg-transparent active:bg-transparent cursor-not-allowed";

  $: buttonClass = twMerge(
    defaultBtn,
    btnFocus,
    noBorder ? btnNoBorder : btnBordered,
    !noShadow && btnShadow,
    size === "xs" && btnXSmall,
    size === "sm" && btnSmall,
    size === "md" && btnMedium,
    type === "primary" &&
      twMerge(btnPrimary, btnPrimaryHover, btnPrimaryActive, loading && btnPrimaryDisabled),
    type === "secondary" &&
      twMerge(btnSecondary, btnSecondaryHover, btnSecondaryActive, loading && btnSecondaryDisabled),
    type === "success" &&
      twMerge(btnSuccess, btnSuccessHover, btnSuccessActive, loading && btnSuccessDisabled),
    type === "ghost" &&
      twMerge(btnGhost, btnGhostHover, btnGhostActive, (loading || disabled) && btnGhostDisabled),
    type === "extra-danger" &&
      twMerge(
        btnDangerSolid,
        btnDangerSolidHover,
        btnDangerSolidActive,
        loading && btnDangerSolidDisabled
      ),
    type === "danger" &&
      twMerge(
        btnDangerText,
        btnDangerTextHover,
        btnDangerTextActive,
        loading && btnDangerTextDisabled
      ),
    type === "dashed" &&
      twMerge(
        btnDashed,
        btnDashedHover,
        btnDashedActive,
        btnDashedFocus,
        loading && btnDashedDisabled
      ),
    type === "warning" &&
      twMerge(btnWarning, btnWarningHover, btnWarningActive, loading && btnWarningDisabled),
    $$props.class
  );
</script>

<svelte:element
  this={"button"}
  type={"button"}
  {...$$restProps}
  class={buttonClass}
  class:cursor-wait={loading}
  disabled={loading || disabled}
  on:click
  on:change
  on:keydown
  on:keyup
  on:touchstart
  on:touchend
  on:touchcancel
  on:mouseenter
  on:mouseleave
  role={"button"}
>
  <div class="flex flex-row w-full h-full gap-4 items-center" class:justify-center={!notCentered}>
    {#if !loading}
      <slot name="iconLeft" />
      <slot />
      <slot name="iconRight" />
    {:else}
      <LoadingSpinner {size} />
      {#if loadingText.length > 0}
        <span> {loadingText}</span>
      {/if}
    {/if}
  </div>
</svelte:element>
