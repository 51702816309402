<script lang="ts">
  import { page } from "@inertiajs/svelte";
  import * as SvelteIcons from "svelte-hero-icons";
  import { twMerge } from "tailwind-merge";
  import { slide } from "svelte/transition";
  import axios from "axios";
  import { sortBy } from "lodash";
  import NavLi from "./NavLi.svelte";
  import NavDivider from "./NavDivider.svelte";
  import PillarLogo from "../../../assets/pillar-bars-diff-length-norm-semi.png";
  import PillarBars from "../../../assets/logo_just_rectangles.svg";
  import type { PermissionsInterface } from "../../../modules/global/models/Permissions";
  import { CLIENT_ORGANISATION } from "../../../modules/global/constants";
  import NavBubble from "./NavBubble.svelte";
  import Tooltip from "../../util/Tooltip/Tooltip.svelte";

  $: current_organisation = $page.props.current_organisation;
  $: team_pillar = current_organisation.name === "Team Pillar";
  $: currentPermissions = $page.props.current_permissions as PermissionsInterface;
  $: notificationCount = $page.props.notification_count;
  $: isClientOrganisation = current_organisation.type === CLIENT_ORGANISATION;

  $: urlParams = $page.url;

  const iconSize = "21";
  const solid = true;

  export let state: "min" | "full" = "full";

  // let state: "min" | "full" = "full";
  let audit_boards: any[] = [];

  const textClass = "text-subcontent truncate max-w-32";
  const groupHeadingClass = twMerge(
    "select-none tracking-wide",
    "uppercase font-semibold text-gray-600 text-subcontent",
    "px-2 pb-2 pt-4",
    "ml-5"
  );

  let showAudit = true;

  const nameSplit = (name) =>
    name
      .split(" ")
      .map((n) => n[0]?.toUpperCase())
      .join(" ")
      .slice(0, 3);

  const cardsToReview = (board) => {
    const field = isClientOrganisation ? "audit_query" : "awaiting_review";
    return board.audit_card_count?.[field] || 0;
  };

  const fetchBoardFavourites = async () => {
    let params = Object.fromEntries(new URLSearchParams($page.url.split("?")[1]));
    await axios.get("/board_favourites.json", { params }).then((response) => {
      audit_boards = sortBy(response.data,
        [
          (board) => -cardsToReview(board),
          (board) => board.created_at
        ]
      );
    });
  };

  $: if (current_organisation && showAudit) {
    fetchBoardFavourites();
  }
</script>

<!-- container -->
<!-- Side Nav Bar-->
<aside
  class="bg-gray-50 text-gray-600 border-r shadow-md z-40 h-full min-h-screen"
  class:w-18={state === "min"}
  class:w-64={state === "full"}
  class:min-w-64={state === "full"}
>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="relative"
    on:click={() => (state === "min" ? (state = "full") : (state = "min"))}
    role="button"
    tabindex="0"
  >
    <SvelteIcons.Icon
      src={state === "min" ? SvelteIcons.ChevronRight : SvelteIcons.ChevronLeft}
      size={"18"}
      solid
      class="cursor-pointer rounded-md h-6 bg-white absolute text-gray-600 border border-opacity-20 text-opacity-70 border-gray-600 -right-[0.055rem] top-[4.5rem] hover:bg-blue-900 hover:text-white hover:border-blue-900 z-[9999]"
    />
  </div>

  <div class="flex flex-col justify-between h-full">
    <ul class="flex flex-col">
      <NavLi {state} class="h-17" disabled>
        <div class="flex flex-row gap-4 items-center">
          <div
            class="h-6 w-6 bg-blue-900 text-white flex items-center justify-center rounded-md p-5 whitespace-nowrap"
            class:-ml-2={state === "min"}
          >
            {nameSplit(current_organisation?.name)}
          </div>
          <span class="font-semibold text-sectionheading text-gray-700 clamp whitespace-normal">
            {state === "min" ? nameSplit(current_organisation?.name) : current_organisation?.name}
          </span>
        </div>
      </NavLi>

      <NavDivider />

      <!-- Items Section -->
      <Tooltip tip="Activity Log" right disabled={state === "full"} class="-mt-9">
        <NavLi href="/activity_log" {state} active={urlParams.includes("activity_log")}>
          <div class="flex flex-row justify-between w-full items-center">
            <div class="flex flex-row gap-4 items-center">
              <SvelteIcons.Icon src={SvelteIcons.LightningBolt} size={iconSize} {solid} />
              <span class={textClass} class:hidden={state === "min"}>Activity Log</span>
            </div>
            <NavBubble hidden={state === "min"} active={notificationCount > 0}>
              <span class="text-subcontent">{notificationCount}</span>
            </NavBubble>
            <div class="relative" class:hidden={state === "full"}>
              <div class="absolute -left-1 -top-3">
                <NavBubble {state} active={notificationCount > 0} />
              </div>
            </div>
          </div>
        </NavLi>
      </Tooltip>

      <Tooltip tip="Account" right disabled={state === "full"} class="-mt-9">
        <NavLi
          href="/account"
          {state}
          active={urlParams.includes("/account") && urlParams !== "/account/directory"}
        >
          <SvelteIcons.Icon src={SvelteIcons.Cog} size={iconSize} {solid} />
          <span class={textClass} class:hidden={state === "min"}>Account</span>
        </NavLi>
      </Tooltip>

      {#if currentPermissions.account.can_validate_reports}
        <Tooltip tip="Report Inspector" right disabled={state === "full"} class="-mt-9">
          <NavLi href="/report_inspector" {state} active={urlParams.includes("/report_inspector")}>
            <SvelteIcons.Icon src={SvelteIcons.DocumentSearch} size={iconSize} {solid} />
            <span class={textClass} class:hidden={state === "min"}>Report Inspector</span>
          </NavLi>
        </Tooltip>
      {/if}

      <NavDivider />

      <!-- <div class={groupHeadingClass} class:hidden={state === "min"}>General</div> -->
      <Tooltip tip="Dashboard" right disabled={state === "full"} class="-mt-9">
        <NavLi
          href="/dashboard"
          {state}
          active={urlParams.includes("dashboard") || urlParams === "/"}
        >
          <SvelteIcons.Icon src={SvelteIcons.Home} size={iconSize} {solid} />
          <span class={textClass} class:hidden={state === "min"}>Dashboard</span>
        </NavLi>
      </Tooltip>

      {#if current_organisation.access.engagements}
        <div>
          <Tooltip tip="Client Management" right disabled={state === "full"} class="-mt-9">
            <NavLi
              href="/client_management"
              {state}
              active={urlParams.includes("client_management")}
            >
              <SvelteIcons.Icon src={SvelteIcons.Collection} size={iconSize} {solid} />
              <span class={textClass} class:hidden={state === "min"}>Client Management</span>
            </NavLi>
          </Tooltip>
        </div>
      {/if}

      <Tooltip tip="My Team" right disabled={state === "full"} class="-mt-9">
        <NavLi href="/account/directory" {state} active={urlParams.includes("account/directory")}>
          <SvelteIcons.Icon src={SvelteIcons.UserGroup} size={iconSize} {solid} />
          <span class={textClass} class:hidden={state === "min"}>My Team</span>
        </NavLi>
      </Tooltip>

      <NavDivider />

      <div class={groupHeadingClass} class:hidden={state === "min"}>Compliance</div>
      {#if current_organisation.access.assessments}
        <Tooltip tip="Assessments" right disabled={state === "full"} class="-mt-9">
          <NavLi
            href="/assessments"
            {state}
            active={urlParams.includes("assessments") && !urlParams.includes("manage")}
          >
            <SvelteIcons.Icon src={SvelteIcons.Home} size={iconSize} {solid} />
            <span class={textClass} class:hidden={state === "min"}>Assessments</span>
          </NavLi>
        </Tooltip>

        <Tooltip tip="Controls" right disabled={state === "full"} class="-mt-9">
          <NavLi href="/manage_controls" {state} active={urlParams.includes("manage_controls")}>
            <SvelteIcons.Icon src={SvelteIcons.Chip} size={iconSize} {solid} />
            <span class={textClass} class:hidden={state === "min"}>Controls</span>
          </NavLi>
        </Tooltip>
      {/if}

      <Tooltip tip="Audits" right disabled={state === "full"} class="-mt-9">
        <NavLi href="/audits" {state} active={urlParams.includes("/audits")}>
          <div class="flex flex-row justify-between w-full items-center">
            <div class="flex flex-row gap-4 items-center">
              <SvelteIcons.Icon src={SvelteIcons.DocumentSearch} size={iconSize} {solid} />
              <span class={textClass} class:hidden={state === "min"}>Audits</span>
            </div>
            <div
              class:hidden={state === "min"}
              on:click|preventDefault={() => (showAudit = !showAudit)}
              on:keypress
              role="link"
              tabindex="0"
            >
              <SvelteIcons.Icon
                src={showAudit ? SvelteIcons.ChevronDown : SvelteIcons.ChevronRight}
                solid
                size={iconSize}
              />
            </div>
          </div>
        </NavLi>
      </Tooltip>

      {#if showAudit && state === "full"}
        <div transition:slide>
          <div class="overflowable max-h-[18rem] max-w-63">
            <!-- Filter by Locked status on board. We don't want to show those -->
            {#each audit_boards as board}
              <NavLi
                href={`/audits/${board?.id}`}
                active={urlParams.includes(`audits/${board?.id}`)}
                class="bg-transparent"
                {state}
              >
                <div class="flex flex-row justify-between w-full items-center">
                  <div class="flex flex-row gap-2 items-center pl-4">
                    <NavBubble
                      active={cardsToReview(board) > 0}
                      tip={`${isClientOrganisation ? "In Audit Query" : "For Review"}`}
                      class="mr-2"
                    >
                      <span class="text-subcontent">{cardsToReview(board)}</span>
                    </NavBubble>
                    <span
                      class={twMerge("whitespace-normal text-subcontent truncate max-h-8 max-w-45")}
                    >
                      {board?.title}
                    </span>
                  </div>
                </div>
              </NavLi>
            {/each}
          </div>
        </div>
      {/if}

      {#if currentPermissions.documents.can_create_documents}
        <Tooltip tip="Documents" right disabled={state === "full"} class="-mt-9">
          <NavLi href="/documents" {state} active={urlParams.includes("documents")}>
            <SvelteIcons.Icon src={SvelteIcons.DocumentDuplicate} size={iconSize} {solid} />
            <span class={textClass} class:hidden={state === "min"}>Documents</span>
          </NavLi>
        </Tooltip>
      {/if}

      <NavDivider />
      {#if !isClientOrganisation}
        <div class={groupHeadingClass} class:hidden={state === "min"}>Support</div>
        {#if team_pillar}
          <Tooltip tip="Support" right disabled={state === "full"} class="-mt-9">
            <NavLi href="/admin" {state} useInertia={false}>
              <SvelteIcons.Icon src={SvelteIcons.Heart} size={iconSize} {solid} />
              <span class={textClass} class:hidden={state === "min"}>Pillar Support</span>
            </NavLi>
          </Tooltip>
        {/if}
      {/if}
    </ul>

    <div
      class="flex items-center w-full"
      class:justify-start={state === "full"}
      class:justify-center={state === "min"}
      class:ml-7={state === "full"}
    >
      <!-- Logo Section -->
      {#if state === "min"}
        <img src={PillarBars} alt="Pillar Icon" class="w-auto h-auto" />
      {:else}
        <img src={PillarLogo} alt="Pillar Icon" class="w-28 h-auto" />
      {/if}
    </div>
  </div>
</aside>

<style lang="scss">
  aside {
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
  }

  .clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .overflowable {
    @apply overflow-y-auto overflow-x-hidden;
    scrollbar-width: thin; /* firefox */
    -ms-overflow-style: thin; /* ie */
    &:hover {
      scrollbar-color: #9ca3af #f3f4f6;
      scrollbar-width: thin;
      -ms-overflow-style: thin; /* ie */
    }
  }
</style>
