export const keypressEvent = (node) => {
  const keypress = (event) => {
    // Enter event
    if (node && event.key === "Enter" && node.contains(event.target)) {
      node.dispatchEvent(new CustomEvent("enter", node));
    }
  };

  document.addEventListener("keypress", keypress, true);

  return {
    destroy() {
      document.removeEventListener("keypress", keypress, true);
    }
  };
};
