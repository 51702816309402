<script lang="ts">
  import { page } from "@inertiajs/svelte";
  import { onMount } from "svelte";
  import { useUserInterface } from "../modules/global/models/User";
  import AmplitudeClientTracking from "../AmplitudeTracking";
  import { amplitudeStore } from "../ApplicationStore";

  const prodEnv = "app.pillargrc";
  const stagingEnv = "dev.pillargrc";

  const keys = {
    [prodEnv]: "64876dce8e74f7fe3104bcf6915d680d",
    [stagingEnv]: "83367b55a863731ca24fdfed4623d9b8",
    local: "",
  };

  $: current_user = $page.props.current_user;
  $: organisation = $page.props.current_organisation;

  // We mount here instead of reactive, so that it only happens once
  onMount(() => {
    const { href } = window.location;
    if (href.includes("localhost")) {
      return;
    }

    const env = window.location.href.includes(prodEnv) ? prodEnv : stagingEnv;
    const amplitude = new AmplitudeClientTracking({ apiKey: keys[env], env }).init();

    // Identify user information
    amplitude.identifyBulkEvent("user", {
      email: current_user?.email,
      id: current_user?.id,
      name: useUserInterface(current_user).fullname(),
      role: useUserInterface(current_user).role,
      created_at: current_user?.created_at,
    });

    amplitude.identifyBulkEvent("organisation", {
      name: organisation?.name,
      type: organisation?.type,
      id: organisation?.id,
    });

    amplitudeStore.set(amplitude);
  });
</script>
