import _ from "lodash";

export type UserRoleTypes = "admin" | "member" | "guest" | "auditor";

export const ROLE_COLOUR_MAPPINGS = {
  admin: "red",
  member: "green",
  guest: "blue",
  auditor: "black",
};

export interface UserInterface {
  id: number;
  user_id: number;
  email: string;
  role: UserRoleTypes;
  first_name: string;
  last_name: string;
  organisation_id: number;
  status: string;
  active: boolean;
  on_board: boolean;
}

export default class User implements UserInterface {
  id: number;
  user_id: number;
  email: string;
  role: UserRoleTypes;
  first_name: string;
  last_name: string;
  organisation_id: number;
  status: string;
  active: boolean;
  on_board: boolean;

  constructor(params: UserInterface) {
    this.id = params.id;
    this.user_id = params.user_id;
    this.email = params.email;
    this.role = params.role;
    this.first_name = params.first_name;
    this.last_name = params.last_name;
    this.organisation_id = params.organisation_id;
    this.status = params.status;
    this.active = params.active;
    this.on_board = params.on_board || false;
  }

  public fullname(): string {
    if (this.first_name == null || !this.last_name == null) {
      return `${this.email}${this.deactivated()} `;
    }

    return `${this.first_name} ${this.last_name}${this.deactivated()}`;
  }

  public fullnameWithEmail(): string {
    if (this.first_name == null || !this.last_name == null) {
      return `${this.email} ${this.deactivated()} `;
    }

    return `${this.first_name} ${this.last_name} <${this.email}> ${this.deactivated()}`;
  }

  public awaitingConfirmation(): boolean {
    return this.status === "pending";
  }

  public initials(): string[] {
    const firstInitial = this.first_name?.[0] || this.email?.[0] || "";
    const lastInitial = this.last_name?.[0] || this.email?.[1] || "";

    return [firstInitial, lastInitial];
  }

  public deactivated(): string {
    if (this.active) {
      return "";
    }

    return " (deactivated)";
  }
}

export const useUserInterface = (params: UserInterface) => new User({ ...params });
