<script lang="ts">
  import { twMerge } from "tailwind-merge";
  import { inertia } from "@inertiajs/svelte";

  export let href = "";
  export let type: "a" = "a";
  export let state: "min" | "full";
  export let useInertia = true;

  const fullNavClass = "w-full";
  const minifiedNavClass = "w-12 overflow-hidden";
  const defaultNavClass = "px-3 gap-4 flex items-center h-9.5";
  const focusNavClass = "focus:text-blue-700";
  const hoverNavClass =
    "hover:text-blue-700 hover:bg-indigo-300/10 cursor-pointer hover:rounded-lg";
  const disabledClass = "cursor-default";

  const activeNavClass = "bg-indigo-300/10 rounded-lg text-blue-700";

  const liClass = "flex flex-col px-3 my-0.5 whitespace-nowrap";

  let navLiClass: string;
  $: navLiClass = twMerge(
    state === "min" ? minifiedNavClass : fullNavClass,
    defaultNavClass,
    !$$props.disabled && focusNavClass,
    !$$props.disabled && hoverNavClass,
    $$props.disabled && disabledClass,
    $$props.active && activeNavClass,
    $$props.class
  );

  const routingAction = useInertia ? inertia : () => {};
</script>

<li class:hover:bg-gray-50={!$$props.disabled} class={liClass}>
  <svelte:element
    this={href ? "a" : "a"}
    type={href ? undefined : type}
    {href}
    {...$$restProps}
    class={navLiClass}
    on:click
    on:change
    on:keydown
    on:keyup
    on:touchstart
    on:touchend
    on:touchcancel
    on:mouseenter
    on:mouseleave
    role={href ? "button" : undefined}
    use:routingAction
  >
    <slot />
  </svelte:element>
</li>
