<script lang="ts">
  import { onMount } from "svelte";
  import { page } from "@inertiajs/svelte";
  import * as Sentry from "@sentry/browser";
  import { useUserInterface } from "../modules/global/models/User";

  $: current_user = $page.props.current_user;
  $: organisation = $page.props.current_organisation;

  let environment;
  const { href } = window.location;
  if (href.includes("dev.")) {
    environment = "staging";
  } else if (href.includes("app.")) {
    environment = "production";
  }

  // TODO:
  // Ideally we would be passing datadog settings from a feature flag
  // platform so we can tweak the sample rates etc without having to
  // deploy a new version of the app.
  onMount(() => {
    if (environment == null) {
      return;
    }

    Sentry.init({
      dsn: "https://cbd83a7befada959ffd96d92df40463f@o4507093498855424.ingest.us.sentry.io/4507093503246336",
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setUser({
      id: current_user?.id,
      email: current_user?.email,
      name: useUserInterface(current_user).fullname(),
      role: useUserInterface(current_user).role,
      created_at: current_user?.created_at,
      organisation: {
        name: organisation?.name,
        type: organisation?.type,
        id: organisation?.id,
      },
    });
  });
</script>
