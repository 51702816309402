<script>
  import { onMount } from "svelte";

  let particles = [];
  let W;
  let H;
  let yeeting;

  function yeet() {
    W = window.innerWidth;
    H = window.innerHeight;
    const mp = 1000; // max particles

    particles = Array.from({ length: mp }, () => ({
      x: Math.random() * W,
      y: Math.random() * H,
      r: Math.random() * 18 + 1,
      d: Math.random() * mp,
      color: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.8)`,
      tilt: Math.floor(Math.random() * 5) - 5,
    }));

    const canvas = document.getElementById("canvas");
    canvas.style.zIndex = "999"
    const ctx = canvas.getContext("2d");
    canvas.width = W;
    canvas.height = H;

    let angle = 0;

    function update() {
      angle += 0.01;
      particles.forEach((p, i) => {
        p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
        p.x += Math.sin(angle) * 2;
        if (p.x > W + 5 || p.x < -5 || p.y > H) {
          if (i % 3 > 0) {
            particles[i] = {
              x: Math.random() * W,
              y: -10,
              r: p.r,
              d: p.d,
              color: p.color,
              tilt: p.tilt,
            };
          }
        }
      });
    }

    function draw() {
      ctx.clearRect(0, 0, W, H);
      particles.forEach((p) => {
        ctx.beginPath();
        ctx.lineWidth = p.r;
        ctx.strokeStyle = p.color;
        ctx.moveTo(p.x, p.y);
        ctx.lineTo(p.x + p.tilt + p.r / 2, p.y + p.tilt);
        ctx.stroke();
      });

      update();
    }

    yeeting = setInterval(draw, 20);
    setTimeout(() => {
      clearInterval(yeeting)
      ctx.clearRect(0, 0, W, H);
      canvas.style.zIndex = "-999"
    }, 5000);
  }

  onMount(() => {
    window.yeet = yeet
  });
</script>

<style>
  /* Add your additional styles here */
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999;
  }
</style>

<canvas id="canvas"></canvas>
