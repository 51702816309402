<script context="module">
  import { page } from "@inertiajs/svelte";
  import { twMerge } from "tailwind-merge";
  import { AppShell } from "@skeletonlabs/skeleton";
</script>

<script>
  import { success, danger } from "../Components/Toasties/notifier";
  import Amplitude from "../Components/Amplitude.svelte";
  import Joy from "../Components/Joy.svelte";
  import Navbar from "../Components/navigation/navbar/Navbar.svelte";
  import GradientHeader from "../Components/header/GradientHeader.svelte";
  import PageContainer from "../Components/Page/PageContainer.svelte";
  import Sentry from "../Components/Sentry.svelte";
  import Userback from "../Components/Userback.svelte";
  import { AUDIT_ORGANISATION } from "../modules/global/constants";

  // Page props sent from shared_props ApplicationController.rb)
  let { current_user, current_organisation } = $page.props;

  $: flash = $page.props.flash;
  $: meta = $page.props.meta;
  $: auditHeader = $page.url.includes("/audits/");

  $: {
    if (flash.notice) {
      success(flash.notice);
    }

    if (flash.error) {
      danger(flash.error);
    }
  }

  $: beaconType = current_organisation.type === AUDIT_ORGANISATION ? "auditor" : "client";

  $: state = "full";
</script>

<svelte:head>
  <title>Pillar - Audits Your Way</title>
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<Amplitude />
<Sentry />
<Joy />

<Userback />

<div style="display: contents" class="h-full overflow-hidden">
  <AppShell>
    <svelte:fragment slot="sidebarLeft">
      <Navbar bind:state />
    </svelte:fragment>
    <svelte:fragment slot="pageHeader">
      <GradientHeader header={meta.page_header} {current_user} />
    </svelte:fragment>

    <slot />
  </AppShell>
</div>

<style>
  :global(html) {
    @apply h-full overflow-hidden;
  }

  :global(body) {
    font-family: "Poppins", sans-serif;
    @apply bg-gray-100 h-full overflow-hidden;
  }
</style>
