<script lang="ts">
  import { useUserInterface } from "../../../modules/global/models/User";

  type userType = {
    first_name: string;
    last_name: string;
    email: string;
  };

  export let user: userType;

  type SizeOptions = "xs" | "sm" | "md" | "lg";
  export let size: SizeOptions = "lg";

  type TypeOptions =
    | "primary"
    | "secondary"
    | "light"
    | "primary white-bordered"
    | "light white-bordered";

  export let type: TypeOptions = "primary";
  export let margin = "my-4";
  export let noMargin = false;
  export let highlight = false;

  $: baseClasses = `circle circle-${size} circle-${type} ${!noMargin && margin} ${
    highlight && "highlight"
  }`;
</script>

<div class={baseClasses}>
  {useUserInterface(user).initials().join("")}
</div>

<style lang="scss">
  .circle {
    @apply rounded-full flex bg-sky-600 text-white items-center justify-center;

    &-xs {
      @apply h-6 text-subcontent font-medium min-w-6 px-[0.8125rem] py-3 w-6;
    }

    &-sm {
      @apply h-8 text-sm min-w-8 px-4 w-8;
    }

    &-md {
      @apply h-18 text-base min-w-18 px-4 w-18;
    }

    &-lg {
      @apply h-28 text-lg min-w-28 px-12 w-28;
    }

    &-primary {
      @apply bg-slate-800 text-white;
    }

    &-secondary {
      @apply bg-sky-600 text-white;
    }
    &-light {
      @apply bg-gray-300 text-neutral-700;
    }

    &.white-bordered {
      @apply border-white rounded-full border;
    }

    &.highlight {
      @apply border-2 border-green-400;
    }
  }
</style>
